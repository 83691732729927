import StripeController from "../stripe_controller";
import { APIClient, parseJSON } from "../../config/https";

export default class extends StripeController {
  static values = {
    amountCents: String,
    consignorPaymentRequestPath: String,
  };

  static targets = [
    "totalAmount",
    "totalAmountText",
    "formContent",
    "successfulContent",
  ];

  connect() {
    super.connect();
  }

  async submit(ev) {
    ev.preventDefault();

    this.handleSubmitButton(true);

    const paymentRequestResult = await this.createPaymentRequest();
    if (!paymentRequestResult) return;

    const stripeConfirmResult = await this.createStripeRequest(
      paymentRequestResult.payment_intent.client_secret
    );
    if (!stripeConfirmResult) return this.handleGenericErrorMessage();

    if (stripeConfirmResult.error)
      return this.handleError(stripeConfirmResult.error.message);

    const paymentResponse = await this.pay(
      paymentRequestResult,
      stripeConfirmResult.paymentIntent.payment_method
    );
    if (paymentResponse !== null) return;

    this.handleSuccess();
  }

  createPaymentRequest() {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        amount_cents: this.amountCentsValue,
        idempotency_key: this.idempotencyKeyTarget.value,
      }),
    };

    return APIClient(this.consignorPaymentRequestPathValue, requestOptions)
      .then(parseJSON)
      .catch(({ response }) => this.handleResponseError(response));
  }

  pay(paymentRequestResult, paymentMethodId) {
    const consignorPaymentRequestId =
      paymentRequestResult.consignor_payment_request.id;
    const paymentIntentId = paymentRequestResult.payment_intent.id;

    return APIClient(
      `${this.consignorPaymentRequestPathValue}/${consignorPaymentRequestId}/pay`,
      {
        method: "POST",
        body: JSON.stringify({
          payment_intent_id: paymentIntentId,
          payment_method_id: paymentMethodId,
          update_credit_card_on_file: this.updateCreditCardOnFile,
          use_saved_card: this.useExistingCard,
          idempotency_key: this.idempotencyKeyTarget.value,
        }),
      }
    )
      .then(parseJSON)
      .catch(({ response }) => this.handleResponseError(response));
  }

  handleSuccess() {
    this.formContentTarget.classList.add("hidden");
    this.successfulContentTarget.classList.remove("hidden");
  }

  handleBalancePositive() {
    this.submitButtonTarget.classList.add("hidden");
    this.totalAmountTarget.classList.add("hidden");
  }

  handleResponseError(response) {
    if (response?.balance?.cents) {
      this.amountCentsValue = response.balance.cents;
      this.totalAmountTextTarget.innerText = response.balance.text;
    }

    if (response?.error_code === "balance_is_positive") {
      this.handleBalancePositive();
    }

    this.handleError(response.error);
  }
}
