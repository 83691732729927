import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["options"];

  connect() {
    useClickOutside(this);
  }

  open(event) {
    event.preventDefault();
    this.optionsTarget.classList.remove("hidden");
  }

  close() {
    this.optionsTarget.classList.add("hidden");
  }
}
