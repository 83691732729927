import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link"
export default class extends Controller {
  disable() {
    this.element.dataset.disabled = "";
  }

  reenableAfter() {
    setTimeout(() => {
      this.element.removeAttribute("data-disabled");
    }, 3000);
  }
}
