import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="image-lazy-loader"
export default class extends Controller {
  static targets = ["image", "root"];

  static values = {
    rootMargin: String,
    threshold: Array,
  };

  static marginValue = "0px";

  connect() {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.src = entry.target.dataset.src;
            entry.target.classList.add("in-view");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      }
    );

    this.imageTargets.forEach((target) => {
      const { placeholder } = target.dataset;
      target.addEventListener("error", (event) => {
        event.target.src = placeholder;
      });

      target.addEventListener("load", (event) => {
        event.target.classList.add("image-loaded");
      });

      observer.observe(target);
    });
  }

  get rootMargin() {
    return this.hasRootMarginValue ? this.rootMarginValue : this.marginValue;
  }

  get root() {
    return this.hasRootTarget ? this.rootTarget : null;
  }

  get threshold() {
    return this.hasThresHoldValue ? this.thresholdValue : [0.5];
  }
}
