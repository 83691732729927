import React from "react";
import { TicketProductModal as TicketProductModalComponent } from "@stadiumgoods/seller-portal-frontend/dist-src";

function TicketProductModal({ spContext, sgp, createSellerProduct = false }) {
  return (
    <TicketProductModalComponent
      spContext={spContext}
      sgp={sgp}
      createSellerProduct={createSellerProduct}
    />
  );
}

export default TicketProductModal;
