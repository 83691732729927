import ErrorMessage from "./error";

export default class {
  constructor(form, callback) {
    this.form = form;
    this.callback = callback;

    this.allFields().forEach(this.addEventListener);
  }

  destroy() {
    this.allFields().forEach(this.removeEventLister);
  }

  addEventListener = (el) => this.handleFieldRegistration(el, true);

  removeEventLister = (el) => this.handleFieldRegistration(el, false);

  inputRequiresVisit(type) {
    return ["text", "email", "password", "search", "tel", "url"].includes(type);
  }

  handleFieldRegistration(el, bool) {
    const action = bool ? "addEventListener" : "removeEventListener";

    if (el.dataset.noValidate) return;
    if (el.nodeName === "INPUT" && this.inputRequiresVisit(el.type)) {
      el[action]("blur", this.validateOnBlur);
    }

    el[action]("input", this.validate);
    el[action]("invalid", this.validate);
  }

  validateOnBlur = (e) => {
    e.target.dataset.visited = true;
    this.validate(e);
  };

  validate = (e) => {
    e.preventDefault();

    this.callback(e, {
      error: new ErrorMessage(e.target).message(),
      processedValidation: this.shouldValidate(e),
    });
  };

  shouldValidate(e) {
    const { target } = e;

    if (
      target.nodeName === "INPUT" &&
      this.inputRequiresVisit(target.type) &&
      target.dataset.visited !== "true" &&
      e.type !== "invalid" &&
      !target.validity.valid
    ) {
      return false;
    }

    return true;
  }

  allFields() {
    return Array.from(
      this.form.querySelectorAll(
        "input:not([type^=hidden]):not([type^=submit]), select, textarea"
      )
    );
  }
}
