import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="inventories"
export default class extends Controller {
  static targets = ["confirmSection", "resetButton"];

  resetPrices() {
    this.resetButtonTargets.forEach((btn) => {
      btn.dispatchEvent(new Event("click"));
    });

    this.confirmSectionTarget.classList.add("hidden");
  }

  priceChange() {
    this.confirmSectionTarget.classList.toggle("hidden", !this.anyChecked);
  }

  get checkboxes() {
    return [...this.element.querySelectorAll("input[type=checkbox]")];
  }

  get anyChecked() {
    return this.checkboxes.length && this.checkboxes.some((box) => box.checked);
  }
}
