import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="create-appointment"
export default class extends Controller {
  static targets = [
    "pickupArea",
    "datepicker",
    "timeRadio",
    "selectDateAndTime",
    "selectCartons",
    "cartonInput",
    "maxCartonError",
    "maxCartonByDate",
    "step"
  ];

  static values = {
    availability: Object
  };

  initialize() {
    this.index = 0;
    this.ticketSelectedQuantity = 0;
    this.currentDropOffLocation = this.pickupAreaTarget.value.toLowerCase();
    this.currentMaxAmountCartons = this.maxAmountCartonsValue;
    this.maxCartonsThresholdForMessage = this.maxAmountCartonsValue;

    this.setMaxCartonsPerDay();
    this.setCartonsThresholdForMessage();
    this.showCurrentStep();
  }

  setMaxCartonsPerDay() {
    this.maxCartonsPerDay = this.availabilityValue[
      this.currentDropOffLocation
    ].max_amount_cartons;
  }

  setCartonsThresholdForMessage() {
    this.maxCartonsThresholdForMessage = this.availabilityValue[
      this.currentDropOffLocation
    ].cartons_threshold_limit_for_message;
  }

  pickupAreaChanged(event) {
    this.currentDropOffLocation = event.target.value.toLowerCase();
    const blockedDays = this.availabilityValue[this.currentDropOffLocation]
      .blocked_days;

    this.setMaxCartonsPerDay();
    this.setCartonsThresholdForMessage();
    this.validateCartonQuantityPerDay();
    this.datepickerTarget.setAttribute(
      "data-air-datepicker-blocked-days-value",
      JSON.stringify(blockedDays)
    );

    this.datetimeChanged();
  }

  datetimeChanged() {
    this.validateCartonQuantityPerDay();

    this.selectDateAndTimeTarget.disabled =
      !this.datepickerTarget.value || !this.timeSelected();
  }

  timeSelected() {
    return this.timeRadioTargets.some(radio => radio.checked);
  }

  cartonQuantityChanged() {
    this.updateSelectCartonsTarget();
  }

  validateCartonQuantityPerDay() {
    const pickupArea = this.currentDropOffLocation;
    const date = this.datepickerTarget.value;
    const availablePerDay = this.availabilityValue[pickupArea]
      .available_cartons_per_day[date];

    this.currentMaxAmountCartons = availablePerDay || this.maxCartonsPerDay;

    if (
      date &&
      this.currentMaxAmountCartons <= this.maxCartonsThresholdForMessage
    ) {
      Array.from(this.maxCartonByDateTarget.children).forEach(element => {
        element.innerHTML = this.currentMaxAmountCartons;
      });

      this.maxCartonByDateTarget.classList.remove("hidden");
    } else {
      this.maxCartonByDateTarget.classList.add("hidden");
    }
  }

  cartonQuantity() {
    return this.cartonInputTargets.reduce((acc, element) => {
      return (acc += parseInt(element.value));
    }, 0);
  }

  addTicketRow() {
    this.ticketSelectedQuantity += 1;
  }

  cartonInputTargetConnected() {
    this.updateSelectCartonsTarget();
  }

  cartonInputTargetDisconnected() {
    this.ticketSelectedQuantity -= 1;
    this.updateSelectCartonsTarget();
  }

  updateSelectCartonsTarget() {
    const aboveMaximumCartons =
      this.cartonQuantity() > this.currentMaxAmountCartons;

    if (aboveMaximumCartons) {
      this.maxCartonErrorTarget.children[0].innerHTML = this.currentMaxAmountCartons;
      this.maxCartonErrorTarget.classList.remove("hidden");
    } else {
      this.maxCartonErrorTarget.classList.add("hidden");
    }

    this.selectCartonsTarget.disabled =
      this.ticketSelectedQuantity === 0 || aboveMaximumCartons;
  }

  afterSubmission(e) {
    if (!e.detail.success) {
      this.index = 0;
      this.showCurrentStep();
    }
  }

  next() {
    this.index++;
    this.showCurrentStep();
    this.updateSelectCartonsTarget();
  }

  previous() {
    this.index--;
    this.showCurrentStep();
    this.updateSelectCartonsTarget();
  }

  showCurrentStep() {
    this.stepTargets.forEach((element, index) => {
      element.hidden = index !== this.index;
    });
  }
}
