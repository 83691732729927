import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="alert"
export default class extends Controller {
  static values = {
    dismissAfter: Number,
  };

  connect() {
    if (this.hasDismissAfterValue) {
      setTimeout(() => {
        this.close();
      }, this.dismissAfterValue);
    }
  }

  close() {
    this.element.remove();
  }
}
