import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["options", "input"];

  static values = {
    selectedOptions: Array,
  };

  connect() {
    useClickOutside(this);
  }

  change(event) {
    const { label } = event.currentTarget.dataset;

    this.selectedOptionsValue = this.selectedOptionsValue.includes(label)
      ? this.selectedOptionsValue.filter((el) => el !== label)
      : this.selectedOptionsValue.concat([label]);
  }

  open(event) {
    event.preventDefault();
    this.optionsTarget.classList.remove("hidden");
  }

  close(event) {
    event.preventDefault();
    this.optionsTarget.classList.add("hidden");
  }

  selectedOptionsValueChanged() {
    this.inputTarget.value = this.selectedOptionsValue.join(", ");
  }
}
