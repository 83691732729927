export const APIClient = async (url, options) => {
  const token = document
    .getElementsByName("csrf-token")[0]
    ?.getAttribute("content");

  const csrfToken = token ? { "X-CSRF-Token": token } : {};

  return await fetch(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...csrfToken,
    },
  });
};

export const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json().then((json) => {
    if (response.ok) {
      return json;
    }

    const error = {
      response: {
        status: response.status,
        statusText: response.statusText,
        ...json,
      },
    };

    return Promise.reject(error);
  });
};
