import { Controller } from "@hotwired/stimulus";

const BLACK = "#131313";
const GRAY_200 = "#EFEFEF";

export default class extends Controller {
  static targets = ["minKnob", "maxKnob", "minInput", "maxInput"];

  static values = {
    minLimit: Number,
    maxLimit: Number,
    step: Number
  };

  initialize() {
    this.prevMinValue = this.fetchMinInput;
    this.prevMaxValue = this.fetchMaxInput;

    this.updateKnobs();
  }

  onMinInputChange() {
    const minValue = this.fetchMinInput;

    if (isNaN(minValue)) {
      this.minInputTarget.value = this.prevMinValue;
      return;
    }

    if (minValue < this.minLimitValue) {
      this.minInput = this.minLimitValue;
    } else if (minValue >= this.fetchMaxInput) {
      this.minInput = Math.min(this.maxLimitValue - this.stepValue, minValue);
      this.maxInput = Math.min(
        this.fetchMinInput + this.stepValue,
        this.maxLimitValue
      );
    } else {
      this.minInput = minValue;
    }

    this.updateKnobs();
  }

  onMaxInputChange() {
    const maxValue = this.fetchMaxInput;

    if (isNaN(maxValue)) {
      this.maxInputTarget.value = this.prevMaxValue;
      return;
    }

    if (maxValue > this.maxLimitValue) {
      this.maxInput = this.maxLimitValue;
    } else if (maxValue <= this.fetchMinInput) {
      this.maxInput = Math.max(this.minLimitValue + this.stepValue, maxValue);
      this.minInput = Math.max(
        this.fetchMaxInput - this.stepValue,
        this.minLimitValue
      );
    } else {
      this.maxInput = maxValue;
    }

    this.updateKnobs();
  }

  onMinKnobSlide() {
    if (this.minKnob === this.prevMinValue) return;

    this.minInput = this.applyStepCorrection(
      Math.min(this.maxLimitValue - this.stepValue, this.minKnob)
    );

    const minValue = this.fetchMinInput;

    if (minValue >= this.fetchMaxInput) {
      this.maxInput = this.applyStepCorrection(
        Math.min(minValue + this.stepValue, this.maxLimitValue)
      );
    }

    this.updateKnobs();
  }

  onMaxKnobSlide() {
    if (this.maxKnob === this.prevMaxValue) return;

    this.maxInput = this.applyStepCorrection(
      Math.max(this.minLimitValue + this.stepValue, this.maxKnob)
    );

    const maxValue = this.fetchMaxInput;

    if (maxValue <= this.fetchMinInput) {
      this.minInput = this.applyStepCorrection(
        Math.max(maxValue - this.stepValue, this.minLimitValue)
      );
    }

    this.updateKnobs();
  }

  updateKnobs() {
    this.minKnobTarget.value = this.fetchMinInput;
    this.maxKnobTarget.value = this.fetchMaxInput;

    const rangeDistance = this.maxLimitValue - this.minLimitValue;

    const fromPosition =
      ((this.minKnobTarget.value - this.minLimitValue) / rangeDistance) * 100;
    const toPosition =
      ((this.maxKnobTarget.value - this.minLimitValue) / rangeDistance) * 100;

    const gradientStops = `${GRAY_200} 0%, ${GRAY_200} ${fromPosition}%, ${BLACK} ${fromPosition}%, ${BLACK} ${toPosition}%, ${GRAY_200} ${toPosition}%, ${GRAY_200} 100%`;

    this.maxKnobTarget.style.background = `linear-gradient(to right, ${gradientStops})`;
  }

  applyStepCorrection(value) {
    if (value > this.maxLimitValue - (this.maxLimitValue % this.stepValue)) {
      return this.maxLimitValue;
    } else {
      return Math.ceil(value / this.stepValue) * this.stepValue;
    }
  }

  get fetchMinInput() {
    return Number(this.minInputTarget.value);
  }

  set minInput(value) {
    this.minInputTarget.value = this.prevMinValue = value;
  }

  get fetchMaxInput() {
    return Number(this.maxInputTarget.value);
  }

  set maxInput(value) {
    this.maxInputTarget.value = this.prevMaxValue = value;
  }

  get minKnob() {
    return Number(this.minKnobTarget.value);
  }

  get maxKnob() {
    return Number(this.maxKnobTarget.value);
  }
}
