/**
 * A fake StripeHook to disable external calls in test environment.
 * Done as a singleton to track double submission of the form.
 */
export class FakeStripeHook {
  /**
   * Singleton implementation
   */

  static instance;

  constructor() {
    if (FakeStripeHook.instance) {
      return FakeStripeHook.instance;
    }
    FakeStripeHook.instance = this;
    this.triggeredCounter = 0;
  }

  static getInstance() {
    if (!FakeStripeHook.instance) {
      FakeStripeHook.instance = new FakeStripeHook();
    }
    return FakeStripeHook.instance;
  }

  /**
   * Custom logic
   */
  triggeredCounter = 0;

  confirmCardPayment(stripeMode) {
    this.triggeredCounter += 1;

    return {
      error: this.fakeStripeError(stripeMode),
      paymentIntent: { payment_method: "fakeStripePaymentMethod" },
    };
  }

  fakeStripeError(stripeMode) {
    if (this.triggeredCounter > 1) {
      return {
        type: "api_error",
        message: "Stripe form submitted more than once.",
      };
    }

    if (stripeMode === "mockFailure") {
      return {
        type: "api_error",
        message: "Stripe failure.",
      };
    }

    return undefined;
  }
}

export const getStripeMode = (featureFlags) => {
  if (featureFlags.stub_stripe && featureFlags.stub_stripe_with_error) {
    return "mockFailure";
  } else if (featureFlags.stub_stripe) {
    return "mockSuccess";
  }

  return "normal";
};
