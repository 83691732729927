import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { APIClient } from "../../../config/https";

// Connects to data-controller="ticket-recent-product-searches"
export default class extends Controller {
  static targets = ["frame", "input", "searchItem"];

  connect() {
    useClickOutside(this);
  }

  fetchRequest() {
    APIClient(`/sellers/search/products`, { method: "GET" })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  clickOutside() {
    this.close();
  }

  searchItemTargetConnected() {
    this.toggleBorderRadiusInput();
  }

  searchItemTargetDisconnected() {
    this.toggleBorderRadiusInput();
  }

  totalSearchItems() {
    return this.searchItemTargets.length;
  }

  showSearches() {
    this.frameTarget.hidden = false;

    this.toggleBorderRadiusInput();
    this.fetchRequest();
  }

  toggleBorderRadiusInput() {
    if (this.totalSearchItems() > 0) {
      this.inputTarget.classList.add("bottom-border-radius-none");
      this.inputTarget.classList.add("focus-always-on");
      this.frameTarget.classList.remove("hidden");
    } else {
      this.frameTarget.classList.add("hidden");
      this.inputTarget.classList.remove("bottom-border-radius-none");
      this.inputTarget.classList.remove("focus-always-on");
    }
  }

  selectSearchTerm(e) {
    this.close();
    this.inputTarget.value = e.target.innerText;
  }

  close() {
    this.inputTarget.classList.remove("bottom-border-radius-none");
    this.inputTarget.classList.remove("focus-always-on");
    this.frameTarget.hidden = true;
  }
}
