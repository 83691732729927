import Cookies from "js-cookie";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-banner"
export default class extends Controller {
  static values = {
    timestamp: String,
  };

  close() {
    Cookies.set("custom_banner_closed", this.timestampValue, Infinity);
    this.element.classList.add("hidden");
  }
}
