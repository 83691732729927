import { Controller } from "@hotwired/stimulus";

import IntlTelInput from "intl-tel-input";

const DEFAULT_COUNTRY_CODE = "US";

// Connects to data-controller="phone-input-intl"
export default class extends Controller {
  static targets = ["country", "phone", "hiddenInput", "error"];

  initialize() {
    const input = this.phoneTarget;
    const { country } = this;

    if (!window.intlTelInputGlobals && !input) return;

    this.intlInput = IntlTelInput(input, {
      initialCountry: country || DEFAULT_COUNTRY_CODE,
      autoPlaceholder: "off",
      // eslint-disable-next-line import/extensions, global-require
      utilsScript: require("intl-tel-input/build/js/utils.js"),
    });

    this.utils = window.intlTelInputUtils;

    if (this.phoneTarget.value) {
      this.validatePhone();
    }
  }

  get country() {
    if (this.hasCountryTarget) {
      return this.countryTarget.value;
    }
    return null;
  }

  countryChanged() {
    if (this.hasCountryTarget) {
      this.intlInput.setCountry(this.countryTarget.value);
      this.inputChanged();
    }
  }

  inputChanged() {
    const { value } = this.phoneTarget;

    this.phoneTarget.value = this.getInternationalValue(value);
    this.hiddenInputTarget.value = this.intlInput.getNumber(
      this.utils.numberFormat.E164
    );

    this.handleLabelFocus(true);
  }

  handleFocus() {
    this.handleLabelFocus(true);
  }

  validatePhone() {
    this.handleLabelFocus();

    if (this.intlInput.isValidNumber()) {
      this.errorTarget.innerHTML = "";
      return;
    }

    const countryCode =
      this.intlInput.getSelectedCountryData().iso2 || DEFAULT_COUNTRY_CODE;

    const error = this.utils.getExampleNumber(
      countryCode,
      true,
      window.intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE
    );
    const message = this.phoneTarget.dataset.errorMessage || "Example: ";
    const errorMessage = [message, error].join(" ");

    this.errorTarget.classList.remove("hidden");
    this.errorTarget.innerHTML = errorMessage;
  }

  getInternationalValue(phone) {
    return this.utils
      .formatNumber(
        phone,
        this.intlInput.getSelectedCountryData().iso2,
        this.utils.numberType.FIXED_LINE_OR_MOBILE
      )
      ?.trim()
      ?.replace(/\s/g, "-");
  }

  handleLabelFocus(force = false) {
    const label = this.phoneTarget
      .closest(".sg-input")
      .getElementsByTagName("label")[0];

    if (this.phoneTarget.value !== "" || force) {
      label.classList.add("focus");
    } else {
      label.classList.remove("focus");
    }
  }
}
