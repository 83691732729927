import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="copy-clipboard"
export default class extends Controller {
  static targets = ["input"];

  copyToClipboard() {
    navigator.clipboard.writeText(this.inputTarget.value);
  }
}
