import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="header"
export default class extends Controller {
  static targets = [
    "open",
    "close",
    "openProfile",
    "closeProfile",
    "menuIcon",
    "menuProfileIcon",
    "navbar",
    "profileNavbar",
    "overlay",
  ];

  open() {
    this.showNavbar(this.navbarTarget);
    this.hideNavbar(this.profileNavbarTarget);

    this.showElement(this.openProfileTarget);
    this.hideElement(this.closeProfileTarget);
    this.hideElement(this.overlayTarget);

    this.toggleIcons(this.menuIconTargets);
    this.setBodyOverflow("hidden");
  }

  close() {
    this.hideNavbar(this.navbarTarget);
    this.toggleIcons(this.menuIconTargets);
    this.setBodyOverflow("unset");
  }

  openProfileMenu() {
    this.showNavbar(this.profileNavbarTarget);
    this.hideNavbar(this.navbarTarget);

    this.showElement(this.openTarget);
    this.hideElement(this.closeTarget);
    this.showElement(this.overlayTarget);

    this.toggleIcons(this.menuProfileIconTargets);
    this.setBodyOverflow("hidden");
  }

  closeProfileMenu() {
    this.hideNavbar(this.profileNavbarTarget);
    this.hideElement(this.overlayTarget);

    this.toggleIcons(this.menuProfileIconTargets);
    this.setBodyOverflow("unset");
  }

  toggleIcons(iconTargets) {
    iconTargets.forEach((item) => item.classList.toggle("hide-menu-icon"));
  }

  showNavbar(navbar) {
    navbar.classList.add("show-navbar");
  }

  hideNavbar(navbar) {
    navbar.classList.remove("show-navbar");
  }

  showElement(element) {
    element.classList.remove("hide-menu-icon");
  }

  hideElement(element) {
    element.classList.add("hide-menu-icon");
  }

  setBodyOverflow(value) {
    document.body.style.overflow = value;
  }
}
