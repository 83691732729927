import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static targets = ["wrapper"];

  toggle(e) {
    const input = this.element.getElementsByTagName("input")[0];

    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }

    this.wrapperTarget.querySelectorAll("svg").forEach(element => {
      element.classList.toggle("hidden");
    });
  }
}
