import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="upload-csv-ticket"
export default class extends Controller {
  afterSubmission(event) {
    if (event.detail.fetchResponse.response.redirected) {
      window.location.assign(event.detail.fetchResponse.response.url);
    } else {
      document.getElementById("update-drawer")?.click(); // Force react to update
    }
  }
}
