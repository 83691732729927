import { Controller } from "@hotwired/stimulus";
import GtmController from "../../gtm_controller";

// Connects to data-controller="inventories-review-panel"
export default class extends Controller {
  static values = {
    inventoryQuantity: Number,
  };

  initialize() {
    this.gtm = new GtmController(this);

    if (this.inventoryQuantityValue === 0) {
      this.gtm.track({
        gtmCategory: "inventory_supply",
        gtmAction: "review_panel_without_items",
      });
    }
  }
}
