import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="collapse-filter"
export default class extends Controller {
  static targets = ["icon", "filters"];

  connect() {
    this.slideUp();
  }

  slideUp() {
    this.filtersTarget.style.height = 0;
    this.filtersTarget.setAttribute("aria-expanded", false);
  }

  slideDown() {
    const height = this.filtersTarget.scrollHeight;
    this.filtersTarget.style.height = `${height}px`;
    this.filtersTarget.setAttribute("aria-expanded", true);
  }

  toggle() {
    if (this.isOpen()) {
      this.slideUp();
    } else {
      this.slideDown();
    }

    this.iconTargets.forEach((e) => e.classList.toggle("hidden"));
  }

  selectOne(e) {
    this.element
      .querySelectorAll(
        `input[type="checkbox"]:not(input[id="${e.target.id}"])`
      )
      .forEach((e) => (e.checked = false));
  }

  isOpen() {
    return this.filtersTarget.getAttribute("aria-expanded") === "true";
  }
}
