import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="turbo-active-link"
export default class extends Controller {
  static classes = ["active", "inactive"];

  static targets = ["item"];

  connect() {
    this.activeLink = null;
  }

  disconnect() {
    this.activeLink = null;
  }

  handleTurboClick(event) {
    this.itemTargets.forEach((element) => {
      element.classList.add(this.inactiveClass);
      element.classList.remove(this.activeClass);
    });

    this.activeLink = event.target.closest("a");
    this.activeLink.classList.add(this.activeClass);
  }
}
