import React from "react";
import { CreatePromotionModal } from "@stadiumgoods/seller-portal-frontend/dist-src";

function LegacyCreatePromotionModal({ spContext }) {
  return (
    <CreatePromotionModal spContext={spContext} initialIsModalOpen={true} />
  );
}

export default LegacyCreatePromotionModal;
