import { Controller } from "@hotwired/stimulus";

import AirDatepicker from "air-datepicker";
import { config } from "../config/air_datepicker";

function disableCell({ date, blockedDays = [], disableDaysOfWeek, cellType }) {
  const isoDate = date.toISOString().split("T")[0];

  if (
    cellType === "day" &&
    (disableDaysOfWeek.includes(date.getDay()) || blockedDays.includes(isoDate))
  ) {
    return {
      disabled: true,
    };
  }

  return {};
}

// Connects to data-controller="air-datepicker"
export default class extends Controller {
  static datePickerObj;

  static values = { blockedDays: Array, default: [] };

  connect() {
    this.datePickerObj = new AirDatepicker(this.element, this.initialOptions());
  }

  initialOptions() {
    const disableDaysOfWeek = this.data.get("disable-days-of-week") || [];
    const blockedDays = this.data.get("blocked-days-value") || [];

    return {
      inline: true,
      minDate: this.data.get("min-date") ?? "",
      maxDate: this.data.get("max-date") ?? "",
      startDate: this.data.get("start-date") ?? "",
      onSelect: () => {
        this.element.dispatchEvent(new CustomEvent("change"));
      },
      onRenderCell: (cell) => {
        return disableCell({ disableDaysOfWeek, blockedDays, ...cell });
      },
      ...config,
    };
  }

  blockedDaysValueChanged() {
    this.datePickerObj?.clear();
    this.datePickerObj?.update(this.initialOptions());
  }

  disconnect() {
    this.datePickerObj.destroy();
  }
}
