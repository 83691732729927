import { Controller } from "@hotwired/stimulus";
import GtmController from "./gtm_controller";

// Connects to data-controller="authentication"
export default class extends Controller {
  static targets = [
    "login",
    "createAccount",
    "createAccountEU",
    "forgotPassword",
  ];

  initialize() {
    this.gtm = new GtmController(this);
  }

  hideAll() {
    this.loginTarget.classList.add("hidden");
    this.createAccountTarget.classList.add("hidden");
    this.forgotPasswordTarget.classList.add("hidden");
    this.createAccountEUTarget.classList.add("hidden");
  }

  showLogin() {
    this.hideAll();
    this.showElement(this.loginTarget);
    this.showElement(this.loginTarget.querySelector(".login"));
    this.hideElement(
      this.loginTarget.querySelector(".two-factor-authentication")
    );
  }

  showCreateAccount() {
    this.hideAll();
    this.showElement(this.createAccountTarget);
    this.showElement(
      this.createAccountTarget.querySelector(".create-account-form")
    );
    this.hideElement(
      this.createAccountTarget.querySelector(".success-message")
    );
  }

  showCreateAccountEU() {
    this.hideAll();
    this.showElement(this.createAccountEUTarget);
    this.showElement(
      this.createAccountEUTarget.querySelector(".create-eu-account-form")
    );
    this.hideElement(
      this.createAccountEUTarget.querySelector(".success-message")
    );
  }

  showForgotPassword() {
    this.hideAll();
    this.showElement(this.forgotPasswordTarget);
    this.showElement(
      this.forgotPasswordTarget.querySelector(".forget-password-form")
    );
    this.hideElement(
      this.forgotPasswordTarget.querySelector(".success-message")
    );
  }

  afterSubmissionForgetPassword(event) {
    if (event.detail.success) {
      this.showElement(
        this.forgotPasswordTarget.querySelector(".success-message")
      );
      this.hideElement(
        this.forgotPasswordTarget.querySelector(".forget-password-form")
      );
    }
  }

  afterSubmissionLogin(event) {
    if (event.detail.success) {
      this.gtm.track({
        gtmCategory: "login",
        gtmAction: "login_success",
        gtmLabel: "logged_in",
      });
    } else {
      // wait for element to show on the UI
      setTimeout(() => {
        const errorMessage = this.loginTarget.querySelector(
          "#login_errors .sg-error"
        ).innerText;

        this.gtm.track({
          gtmCategory: "login",
          gtmAction: "login_failure",
          gtmLabel: "login_error",
          gtmValue: errorMessage,
        });
      }, 300);
    }
  }

  afterSubmissionCreateAccount(event) {
    if (event.detail.success) {
      this.gtm.track({
        gtmCategory: "create_account",
        gtmAction: "registration_success",
        gtmLabel: "account_created",
      });

      this.showElement(
        this.createAccountTarget.querySelector(".success-message")
      );
      this.hideElement(
        this.createAccountTarget.querySelector(".create-account-form")
      );
    } else {
      // wait for element to show on the UI
      setTimeout(() => {
        const errorMessage = this.createAccountTarget.querySelector(
          "#create_account_errors .sg-error"
        ).innerText;

        this.gtm.track({
          gtmCategory: "create_account",
          gtmAction: "registration_failure",
          gtmLabel: "registration_error",
          gtmValue: errorMessage,
        });
      }, 300);
    }
  }

  afterSubmissionCreateEUAccount(event) {
    if (event.detail.success) {
      this.showElement(
        this.createAccountEUTarget.querySelector(".success-message")
      );
      this.hideElement(
        this.createAccountEUTarget.querySelector(".create-eu-account-form")
      );
    }
  }

  hideElement(element) {
    element.classList.add("hidden");
  }

  showElement(element) {
    element.classList.remove("hidden");
  }
}
