import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="size-row"
export default class extends Controller {
  static targets = ["serialPriceInput", "serialContent", "loadMoreContent"];

  priceChange({ target: { value } }) {
    // if the values are a range (NaN), it means that serial rows can have different values and must be reset
    const reset = isNaN(value);
    this.updateUrls(value, reset);

    this.serialPriceInputTargets.forEach((input) => {
      if (!input.disabled) {
        input.value = reset ? input.dataset.placeholder : value;
        input.dispatchEvent(new Event("input"));
      }
    });
  }

  // it sets price in the URL to preload prices when sgp-size_code price is changed
  updateUrls(value, reset) {
    if (
      this.hasSerialContentTarget &&
      !this.serialContentTarget.querySelector(".serial-row")
    ) {
      this.updateUrlParams(this.serialContentTarget, value, reset, "src");
    }

    if (this.hasLoadMoreContentTarget)
      this.updateUrlParams(this.loadMoreContentTarget, value, reset, "href");
  }

  updateUrlParams(target, price, reset, urlAttribute) {
    const url = new URL(target[urlAttribute], window.location.href);

    if (reset) {
      url.searchParams.delete("price");
    } else {
      url.searchParams.set("price", price);
    }

    target[urlAttribute] = url.pathname + url.search;
  }
}
