import { Controller } from "@hotwired/stimulus";
import { useClickOutside, useDebounce } from "stimulus-use";

// Connects to data-controller="search-select"
export default class extends Controller {
  static targets = ["input", "frame"];

  static values = { frame: String, url: String };

  static debounces = ["fetchRequest"];

  connect() {
    useClickOutside(this);
    useDebounce(this, { wait: 700 });
  }

  fetchRequest() {
    const params = new URLSearchParams({
      query: this.inputTarget.value,
    }).toString();

    fetch(`${this.urlValue}?${params}`, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document
          ?.getElementsByName("csrf-token")[0]
          ?.getAttribute("content"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  search() {
    this.frameTarget.hidden = false;

    this.fetchRequest();
  }

  clickOutside() {
    this.close();
  }

  close() {
    this.inputTarget.value = "";
    this.frameTarget.hidden = true;
  }
}
