import Cookies from "js-cookie";
import { Controller } from "@hotwired/stimulus";
import GtmController from "./gtm_controller";

const ASP_SLIDER_DEBOUNCE_INTERVAL = 700;

// Connects to data-controller="sidebar-filter"
export default class extends Controller {
  static targets = ["wrapper", "count", "clearAll", "checkbox"];

  static values = {
    filterCookieKey: String,
    gtmCategory: String,
  };

  initialize() {
    this.gtm = new GtmController(this);

    if (window.screen.orientation.type === "portrait-primary") {
      this.wrapperTarget.setAttribute("aria-expanded", false);
    }
  }

  connect() {
    this.setCount();
  }

  clearAll() {
    this.clearCheckboxes();
    this.setCount();

    this.gtm.track({
      gtmCategory: this.gtmCategoryValue,
      gtmAction: "clear_filters_click",
      gtmLabel: "clear_filters",
    });
  }

  update(event) {
    this.dispatch("updated", { detail: {} });
  }

  toggleMobile() {
    this.wrapperTarget.style.display = this.isOpen() ? "none" : "block";
    this.wrapperTarget.setAttribute("aria-expanded", !this.isOpen());
    this.wrapperTarget.style.width = "";
    this.trackEvent();
  }

  toggle() {
    const isOpen = this.isOpen();

    Cookies.set(this.filterCookieKeyValue, !isOpen);
    this.wrapperTarget.style.width = isOpen ? 0 : "226px";
    this.element.style.gridTemplateColumns = isOpen ? "0px 1fr" : "226px 1fr";
    this.wrapperTarget.style.display = "";
    this.wrapperTarget.setAttribute("aria-expanded", !isOpen);
    this.trackEvent();
  }

  trackEvent() {
    const isOpen = this.isOpen();
    const gtmAction = isOpen ? "open_filter_panel" : "close_filter_panel";
    const gtmLabel = isOpen ? "filter_open" : "filter_close";

    this.gtm.track({ gtmCategory: this.gtmCategoryValue, gtmAction, gtmLabel });
  }

  isOpen() {
    return this.wrapperTarget.getAttribute("aria-expanded") === "true";
  }

  clearCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
      // currently accepts only one input range
      if (checkbox.id === "asp-ranges-hidden-input") {
        this.clearAspRangesHiddenCheckbox(checkbox.min, checkbox.max);
      }
    });
  }

  setCount() {
    const count = this.selectedCheckboxes.length;

    this.countTargets.forEach((element) => {
      element.innerHTML = count ? `(${count})` : "";
    });

    this.toggleClearAll(count);
  }

  toggleClearAll(count) {
    this.clearAllTargets.forEach((element) => {
      element.hidden = !count;
    });
  }

  get selectedCheckboxes() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  // currently hardcoded for ASP - TODO: Refactor to accept multiple sliders
  onAspSliderChange() {
    this.cancelOngoingAspRangesFilterRequest();

    const { value: minValue } = document.getElementById("asp-slider-min-input");
    const { value: maxValue } = document.getElementById("asp-slider-max-input");

    const aspRangesHiddenCheckbox = document.getElementById(
      "asp-ranges-hidden-input"
    );
    aspRangesHiddenCheckbox.value = `${minValue}-${maxValue}`;

    this.aspSliderChangeRequestRef = setTimeout(() => {
      aspRangesHiddenCheckbox.checked = false;
      aspRangesHiddenCheckbox.click();
    }, ASP_SLIDER_DEBOUNCE_INTERVAL);
  }

  cancelOngoingAspRangesFilterRequest() {
    if (typeof this.aspSliderChangeRequestRef === "number") {
      clearTimeout(this.aspSliderChangeRequestRef);
    }
  }

  clearAspRangesHiddenCheckbox(minLimit, maxLimit) {
    const minInput = document.getElementById("asp-slider-min-input");
    const maxInput = document.getElementById("asp-slider-max-input");
    const minKnob = document.getElementById("multi-range-atom-min-knob");
    const maxKnob = document.getElementById("multi-range-atom-max-knob");
    const aspRangesHiddenCheckbox = document.getElementById(
      "asp-ranges-hidden-input"
    );

    minInput.value = minLimit;
    maxInput.value = maxLimit;
    aspRangesHiddenCheckbox.value = `${minLimit}-${maxLimit}`;

    const event = new Event("input");

    if (minKnob.value !== minLimit) {
      minKnob.value = minLimit;
      minKnob.dispatchEvent(event);
    } else if (maxKnob.value !== maxLimit) {
      maxKnob.value = maxLimit;
      maxKnob.dispatchEvent(event);
    }
  }
}
