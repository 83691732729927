import * as braze from "@braze/web-sdk";

braze.initialize(process.env.BRAZE_WEB_SDK_API_KEY || '', {
  baseUrl: process.env.BRAZE_WEB_SDK_URL || '',
  allowUserSuppliedJavascript: true,
});

if (process.env.NODE_ENV === 'development') {
  braze.toggleLogging();
}

braze.automaticallyShowInAppMessages();

if (window.consignorId) {
  braze.changeUser(window.consignorId);
}

braze.openSession();
