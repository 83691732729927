import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sidebar"
export default class extends Controller {
  connect() {
    if (!this.element.classList.contains("hidden")) {
      this.setBodyOverflow("hidden");
    }
  }

  close() {
    this.element.classList.add("hidden");
    this.setBodyOverflow("unset");
  }

  setBodyOverflow(value) {
    document.body.style.overflow = value;
  }
}
