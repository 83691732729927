import { Controller } from "@hotwired/stimulus";
import Validator from "./validator";

const config = {
  inputErrorClass: "invalid",
  errorClass: "sg-input-error",
  errorTextParent: "sg-form-group",
  errorTextTag: "div",
  hidden: "hidden",
};

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    this.toggleDisableOnSubmitButton(true);
    this.submitButtonTarget.addEventListener("click", this.removeNoValidate);
    this.validator = new Validator(this.form, this.validationCallback);
  }

  disconnect() {
    this.submitButtonTarget.removeEventListener("click", this.removeNoValidate);
    this.validator.destroy();
  }

  redirect(event) {
    if (event.detail.fetchResponse.response.redirected) {
      this.submitButtonTarget.disabled = true;
      window.location.assign(event.detail.fetchResponse.response.url);
    }
  }

  isValid() {
    this.data.set("noValidate", true);
    return this.form.checkValidity();
  }

  get form() {
    return this.element;
  }

  display({ el, error }) {
    const wrapperElement = el.closest(`.${config.errorTextParent}`);
    const errorElement = wrapperElement.getElementsByClassName(
      config.errorClass
    )[0];

    if (error && errorElement) {
      errorElement.innerHTML = error;
      errorElement.classList.remove(config.hidden);
      el.classList.add(config.inputErrorClass);
    } else if (error && !errorElement) {
      wrapperElement.appendChild(this.createErrorElement(error));
      el.classList.add(config.inputErrorClass);
    } else {
      errorElement?.classList?.add(config.hidden);
      el.classList.remove(config.inputErrorClass);
    }
  }

  createErrorElement(error) {
    const div = document.createElement(config.errorTextTag);
    div.className = config.errorClass;
    div.innerHTML = error;

    return div;
  }

  removeNoValidate = () => this.data.delete("noValidate");

  toggleDisableOnSubmitButton(error) {
    if (this.data.get("disableSubmit") === "false") return;

    if (error) {
      this.submitButtonTarget.disabled = true;
    } else if (this.isValid()) {
      this.submitButtonTarget.disabled = false;
    }
  }

  validationCallback = (event, { error, processedValidation }) => {
    if (event.type === "invalid" && this.data.has("noValidate")) {
      return;
    }

    this.toggleDisableOnSubmitButton(error);

    if (processedValidation) {
      this.display({ el: event.target, error });
    }
  };
}
