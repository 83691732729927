import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["minus", "plus", "input", "reset", "currency", "label", "error", "checked"];

  static values = {
    initial: Number,
    minPrice: Number,
    placeholder: String,
    maxLimit: { type: Number, default: null }
  };

  connect() {
    this.previousValue = this.placeholderValue;
    const currentValue = this.currentNumericValue();
    if (!this.plusTarget.disabled) this.updatePlusButton(currentValue);
    if (!this.minusTarget.disabled) this.updateMinusButton(currentValue);
  }

  minusAmount() {
    this.setInputValue(this.currentNumericValue() - 1);
  }

  plusAmount() {
    this.setInputValue(this.currentNumericValue() + 1);
  }

  resetAmount() {
    this.setInputValue(this.placeholderValue);
  }

  setInputValue(newValue) {
    this.inputTarget.value = newValue;
    this.previousValue = newValue;
    this.onInputChanged();
  }

  currentNumericValue() {
    return this.isInputUnchanged() ? this.initialValue : Number(this.currentInputValue()) || 0;
  }

  setChecked(value) {
    this.checkedTarget.checked = value
  }

  onInputChanged() {
    this.updateResetLink();

    const currentValue = this.currentNumericValue();
    this.updatePlusButton(currentValue);
    this.updateMinusButton(currentValue);

    const isValid = this.isValueValid(currentValue);
    this.setChecked(isValid ? !this.isInputUnchanged() : false)
    this.validateInputField(isValid);
    this.updateTextColor(isValid);
    
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  updateTextColor(isValid) {
    const greenText = isValid && this.minPriceValue && Number(this.currentInputValue()) === this.minPriceValue;
    this.toggleClass(this.currencyTarget, "text-green", greenText);
    this.toggleClass(this.inputTarget, "!text-green", greenText);
  }

  isValueValid(currentValue) {
    return !this.maxLimitValue || currentValue <= this.maxLimitValue;
  }

  validateInputField(isValid) {
    this.toggleClass(this.labelTarget, "!text-red", !isValid);
    this.toggleClass(this.errorTarget, "hidden", isValid);
  }

  updatePlusButton(currentValue) {
    const isMaxLimit = this.maxLimitValue && currentValue >= this.maxLimitValue;
    this.plusTarget.disabled = isMaxLimit;
    this.plusTarget.title = isMaxLimit ? this.errorTarget.innerHTML : "";
    this.toggleClass(this.plusTarget, "cursor-not-allowed", isMaxLimit);
  }

  updateMinusButton(currentValue) {
    const isMinLimit = currentValue <= 1;
    this.minusTarget.disabled = isMinLimit;
    this.toggleClass(this.minusTarget, "cursor-not-allowed", isMinLimit);
  }

  updateResetLink() {
    this.toggleClass(this.resetTarget, "hidden", this.isInputUnchanged());
  }

  onChange() {
    const value = this.currentInputValue();
    const previousValue = this.previousValue;
    let newValue = "";

    if (value) {
      if (!isNaN(value) && Number(value) > 0) {
        newValue = value;
      } else if (isNaN(value) && isNaN(previousValue)) {
        newValue = this.initialValue;
      } else {
        newValue = previousValue;
      }
    }
    this.setInputValue(newValue);
  }

  toggleClass(target, className, condition) {
    target.classList.toggle(className, condition);
  }

  currentInputValue() {
    return this.inputTarget.value;
  }

  isInputUnchanged() {
    return this.currentInputValue() === this.placeholderValue;
  }
}
