import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="remove-dom-element"
export default class extends Controller {

  static values = { confirmMessage: { type: String, default: 'Are you sure?' } }

  remove() {
    if (confirm(this.confirmMessageValue)) {
      this.element.remove();
    }
  }
}
