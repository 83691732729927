import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import { APIClient } from "../config/https";

// Connects to data-controller="password-strength"
export default class extends Controller {
  static targets = ["percentageCircle", "passwordStrengthMessage", "pie"];

  static debounces = ["onChange"];

  connect() {
    useDebounce(this, { wait: 700 });
  }

  fetch(password) {
    const percentageCircle = this.percentageCircleTarget;
    const strengthMessage = this.passwordStrengthMessageTarget;
    const pie = this.pieTarget;

    APIClient(`/users/calculate_password_strength?password=${encodeURI(password)}`)
      .then((response) => response.json())
      .then((data) => {
        const strength = data.password_strength;

        const weakMessage = strengthMessage.querySelector(".weak-message");
        const strongMessage = strengthMessage.querySelector(".strong-message");
        const checkMark = percentageCircle.querySelector(".checkmark");
        const leftSide = percentageCircle.querySelector(".left-side");
        const rightSide = percentageCircle.querySelector(".right-side");
        const halfCircle = percentageCircle.querySelectorAll(".half-circle");

        if (strength <= 0) {
          percentageCircle.classList.add("hidden");
          strengthMessage.classList.add("hidden");
          return;
        }

        percentageCircle.classList.remove("hidden");
        strengthMessage.classList.remove("hidden");
        strengthMessage.classList.add("flex");

        leftSide.style.transform = `rotate(${strength * 3.6}deg)`;

        if (strength === 100) {
          halfCircle.forEach((el) => {
            el.style["border-color"] = "#76BD55";
          });
          checkMark.style.display = "flex";

          weakMessage.style.display = "none";
          strongMessage.style.display = "flex";
        } else {
          halfCircle.forEach((el) => {
            el.style["border-color"] = "";
          });
          checkMark.style.display = "";

          weakMessage.style.display = "flex";
          strongMessage.style.display = "none";
        }

        if (strength > 50) {
          pie.style.clip = "rect(auto, auto, auto, auto)";
          rightSide.style.transform = "rotate(180deg)";
          rightSide.style.display = "";
        } else {
          pie.style.clip = "";
          rightSide.style.transform = "";
          rightSide.style.display = "none";
        }
      })
      .catch(() => {});
  }

  onChange(e) {
    this.fetch(e.target.value);
  }
}
