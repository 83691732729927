import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import tippy from "tippy.js";

import { APIClient } from "../config/https";

// Connects to data-controller='estimated-payout'
export default class extends Controller {
  static values = {
    initialPrice: Number,
    fetchEstimatedPayoutUrl: String,
    debounceInterval: { type: Number, default: 700 },
  };

  static targets = ["content", "loading"];

  static debounces = ["initTippy", "fetch"];

  connect() {
    useDebounce(this, { wait: this.debounceIntervalValue });
    this.initTippy();

    this.fetchEstimatedPayout({
      target: { value: this.initialPriceValue },
    });
  }

  fetchEstimatedPayout({ target: { value } }) {
    if (value === this.previousValue) return;

    this.previousValue = value;
    if (!this.contentTarget.querySelector(".loading-spinner")) {
      this.contentTarget.innerHTML = this.loadingTarget.innerHTML;
    }

    this.fetch(value);
  }

  fetch(value) {
    return APIClient(`${this.fetchEstimatedPayoutUrlValue}&price=${value}`)
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => {
        this.initTippy();
      });
  }

  initTippy() {
    tippy("[data-tippy-hover]", { allowHTML: true });
  }
}
