import { Controller } from "@hotwired/stimulus";
import GtmController from "./gtm_controller";

// Connects to data-controller="stepper"
export default class extends Controller {
  static targets = ["minus", "plus", "input"];

  static values = {
    min: Number,
    max: Number,
    gtmEvents: { type: Object, default: null }
  };

  initialize() {
    this.value = null;
    this.currentChange = null;
    this.prevValueInputText = "";

    this.gtm = new GtmController(this);
  }

  minusAmount() {
    this.currentChange = 'min'
    this.updateValue(-1);
  }

  plusAmount() {
    this.currentChange = 'max'
    this.updateValue(1);
  }

  updateValue(value) {
    const newValue = this.validateValue(this.currentValueFromInput() + value);
    this.setInputValue(newValue);
  }

  setInputValue(newValue) {
    this.inputTarget.value = newValue;
    this.prevValueInputText = newValue;
    this.onInputChanged();

    if (this.gtmEventsValue) this.trackEvent();
  }

  currentValueFromInput() {
    return Number(this.inputTarget.value) || 0;
  }

  trackEvent() {
    const event = this.gtmEventsValue[this.currentChange]
    const gtmParams = {
      gtmAction: event.action,
      gtmLabel: event.label,
      gtmValue: this.currentValueFromInput()
    };

    this.gtm.track({ gtmCategory: event.category, ...gtmParams });
  }

  isValueEmpty() {
    return this.inputTarget.value === "";
  }

  onInputChanged() {
    this.minusTarget.disabled =
      this.isValueEmpty() || this.currentValueFromInput() <= this.minValue;
    this.plusTarget.disabled =
      this.isValueEmpty() || this.currentValueFromInput() >= this.maxValue;
  }

  validateValue(newValue) {
    if (Number.isNaN(newValue)) return this.prevValueInputText;
    if (newValue >= this.maxValue) return this.maxValue;
    if (newValue <= this.minValue) return this.minValue;

    return Number(newValue);
  }

  onChange() {
    if (!this.inputTarget.value) return;
    this.setInputValue(this.validateValue(this.currentValueFromInput()));
  }
}
