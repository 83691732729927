const localeEn = {
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  today: "Today",
  clear: "Clear",
  dateFormat: "mm/dd/yyyy",
  timeFormat: "hh:ii aa",
  firstDay: 0
};

const config = {
  locale: localeEn,
  dateFormat: "yyyy-MM-dd",
  showOtherMonths: false,
  navTitles: {
    days: "MMMM yyyy"
  },
  nextHtml:
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18.2081 16.0556C18.9249 14.5758 19.5954 13.466 20.2197 12.7261H0V11.2695H20.2197C19.5954 10.5296 18.9249 9.41977 18.2081 7.94H19.422C20.8786 9.62786 22.4046 10.8764 24 11.6857V12.3099C22.4046 13.0961 20.8786 14.3446 19.422 16.0556H18.2081Z" fill="#131313"/></svg>',
  prevHtml:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.57803 16.0556C3.12139 14.3446 1.59538 13.0961 0 12.3099V11.6857C1.59538 10.8764 3.12139 9.62786 4.57803 7.94H5.79191C5.09827 9.39665 4.42775 10.5065 3.78035 11.2695H24V12.7261H3.78035C4.42775 13.4891 5.09827 14.599 5.79191 16.0556H4.57803Z" fill="#131313"/></svg>'
};

export { config };
