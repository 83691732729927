import { Controller } from "@hotwired/stimulus";
import { APIClient, parseJSON } from "../../../config/https";

// Connects to data-controller="reports-withdrawn-controller"
export default class extends Controller {
  static targets = ["requestWithdrawalButton", "checkbox"];

  connect() {
    this.updateWithdrawalRequestButton();
  }

  handleRequest(event) {
    if (event.target.getAttribute("disabled")) {
      event.preventDefault();
    }
  }

  enableWithdrawalRequestButton() {
    this.requestWithdrawalButtonTarget.removeAttribute("disabled");
  }

  disabledWithdrawalRequestButton() {
    this.requestWithdrawalButtonTarget.setAttribute("disabled", true);
  }

  updateWithdrawalRequestButton(event) {
    const someIsChecked = this.checkboxTargets.some((checkbox) => {
      return checkbox.checked;
    });

    if (someIsChecked) {
      this.enableWithdrawalRequestButton();
    } else {
      this.disabledWithdrawalRequestButton();
    }
  }

  onCheckboxClicked(event) {
    const checkbox = event.target;
    const serialNumber = checkbox.value;
    checkbox.disabled = true;

    const inDraftWithdrawal = document.getElementById(
      `in_draft_withdrawal_${serialNumber}`
    );

    if (checkbox.checked) {
      APIClient(`/sellers/withdrawal_request_items`, {
        method: "POST",
        body: JSON.stringify({ serial_number: serialNumber }),
      })
        .then(parseJSON)
        .then((data) => {
          inDraftWithdrawal.classList.remove("hidden");
          checkbox.disabled = false;

          this.updateWithdrawalRequestButton();
        })
        .catch((error) => {
          checkbox.disabled = false;
          checkbox.checked = false;

          alert(error);

          this.updateWithdrawalRequestButton();
        });
    } else {
      APIClient(`/sellers/withdrawal_request_items/${serialNumber}`, {
        method: "DELETE",
      })
        .then(parseJSON)
        .then((data) => {
          inDraftWithdrawal.classList.add("hidden");
          checkbox.disabled = false;

          this.updateWithdrawalRequestButton();
        })
        .catch((error) => {
          checkbox.disabled = false;

          this.updateWithdrawalRequestButton();
        });
    }
  }
}
