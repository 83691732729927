import React from "react";
import { InventorySortSelect } from "@stadiumgoods/seller-portal-frontend/dist-src";

function LegacyInventorySortSelect({
  initialSortValue,
  options,
  queryParameter,
}) {
  return (
    <InventorySortSelect
      initialSortValue={initialSortValue}
      options={options}
      queryParameter={queryParameter}
    />
  );
}

export default LegacyInventorySortSelect;
