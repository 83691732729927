import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notifications"
export default class extends Controller {
  static targets = ["sidebar", "unreadTab", "allTab", "content", "loading"];

  open() {
    this.sidebarTarget.classList.remove("hidden");
    this.unreadTabTarget.classList.add("selected");
    this.allTabTarget.classList.remove("selected");
    this.setBodyOverflow("hidden");
  }

  close() {
    this.sidebarTarget.classList.add("hidden");
    this.contentTarget.innerHTML = this.loadingTarget.innerHTML;
    this.setBodyOverflow("unset");
  }

  setBodyOverflow(value) {
    document.body.style.overflow = value;
  }
}
