import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="gtm"
export default class extends Controller {
  trackEvent(event) {
    const dataset = this.dataset(event.target);
    if (!dataset) return;

    const { gtmCategory, gtmAction, gtmLabel, gtmValue } = dataset;

    this.track({ gtmCategory, gtmAction, gtmLabel, gtmValue });
  }

  trackSelectEvent(event) {
    const { options, selectedIndex } = event.target;
    const { gtmCategory, gtmAction, gtmLabel, gtmValue } = options[
      selectedIndex
    ].dataset;

    this.track({ gtmCategory, gtmAction, gtmLabel, gtmValue });
  }

  dataset(target) {
    // Why? In the case below when clicking, the target value is the "svg" and not the "a" tag with the dataset attributes.
    // <a href="https://..." data-gtm-category="category">
    //  <svg>...</svg>
    // </a>

    if (target.dataset.gtmCategory) {
      return target.dataset;
    } else if (target.parentElement.dataset.gtmCategory) {
      return target.parentElement.dataset;
    } else if (target.closest("a").dataset.gtmCategory) {
      return target.closest("a").dataset;
    }
  }

  track({ gtmCategory, gtmAction, gtmLabel, gtmValue }) {
    const eventObject = {
      event: "trackEvent",
      eventCategory: gtmCategory,
      eventAction: gtmAction,
      eventLabel: gtmLabel || "",
      eventValue: gtmValue || "",
      inventoryCount: window.inventoryCount,
      ticketSubCount: window.submittedTicketsCount,
      consignorId: window.consignorId,
    };

    if (process.env.RAILS_ENV === "development")
      console.info("trackEvent", eventObject);

    (window.dataLayer || []).push(eventObject);
  }
}
