import { Controller } from "@hotwired/stimulus";
import GtmController from "./gtm_controller";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["body", "wrapper"];

  static values = {
    gtmEvents: { type: Object, default: null },
  };

  initialize() {
    this.gtm = new GtmController(this);
  }

  connect() {
    document.addEventListener("keydown", this.keyDownHandler.bind(this));

    if (this.wrapperTarget.dataset.open) {
      this.open();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.keyDownHandler.bind(this));

    this.close();
  }

  open() {
    document.body.style.overflow = "hidden";
    this.wrapperTarget.classList.remove("hidden");
  }

  closeOnSubmit(event) {
    event.preventDefault();
    if (!event.detail.success) return;

    this.closeAction();
  }

  closeAction() {
    document.body.style.overflow = "unset";
    this.wrapperTarget.classList.add("hidden");

    this.trackOnClose();

    if (this.hasBodyTarget) {
      setTimeout(() => {
        this.bodyTarget.remove();
      }, 300);
    }
  }

  windowReload() {
    window.location.reload();
  }

  close(event) {
    event.preventDefault();

    let confirmed = true;
    const confirmationMessage =
      this.hasBodyTarget && this.bodyTarget.dataset.confirmOnClose;

    if (confirmationMessage) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      confirmed = confirm(confirmationMessage);
    }

    if (confirmed) {
      this.closeAction();
    }
  }

  keyDownHandler(event) {
    if (event.keyCode === 27) {
      this.close(event);
    }
  }

  trackOnClose() {
    if (!this.gtmEventsValue || !this.gtmEventsValue["on_close"]) return;

    const event = this.gtmEventsValue["on_close"];
    const gtmParams = {
      gtmAction: event.action,
      gtmLabel: event.label,
    };

    this.gtm.track({ gtmCategory: event.category, ...gtmParams });
  }
}
