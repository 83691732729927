import React from "react";
import { StorageFeeInfoBanner } from "@stadiumgoods/seller-portal-frontend/dist-src";

function StorageFeeBanner({ spContext, bannerSection }) {
  return (
    <StorageFeeInfoBanner spContext={spContext} bannerSection={bannerSection} />
  );
}

export default StorageFeeBanner;
