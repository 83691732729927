import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab"];

  toggle(event) {
    if (event.target.classList.contains("selected")) return;

    this.tabTargets.forEach((item) => item.classList.toggle("selected"));
  }
}
